<template>
<div>
  <component :is="'b-card'">
    <!-- Form: Personal Info Form -->
    <validation-observer ref="simpleRules">
      <b-form class="mt-1">
        <!-- Form: User Info Form -->
        <b-row class="mt-1">

          <!-- Field: Password -->
          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('Password')" label-for="newPassword">
              <validation-provider #default="{ errors }" rules="required" name="newPassword" vid="newPassword">
                <b-input-group>
                <b-form-input id="newPassword" v-model="userData.password" :state="errors.length > 0 ? false:null" />
                <b-input-group-append>
                  <b-button @click="generate" variant="outline-primary">
                    {{ $t('Generate') }}
                  </b-button>
                </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="resetPassword()"
              size="sm">
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
                size="16"
              />
              <b-spinner
                v-if="$store.getters.getLoader"
                class="mr-1"
                small
                variant="light"
              />
              {{ $t('Reset Password') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </component>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'

// Alert fade
import { heightFade } from '@core/directives/animations'
// Alert
import AlertService from '@/common/alert.service'

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

import ApiService from '@/common/api.service';
import UserManageService from '@/common/custom-service/UserManageService';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    'height-fade': heightFade,
  },

  data() {
    return {
      required,
      userData: { password: '' },
    }
  },

  methods: {
    resetPassword() {
      const paramId = this.$router.currentRoute.params.id;
      if (paramId === 0) {
        AlertService.warning(this, 'Please Save User Info.');
      }
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const query = {
            userId: paramId,
            newPassword: this.userData.password,
          };

          ApiService.post('user/resetpassword', query, this).then(response => {
            if (response.statusCode === 200) {
              this.$toast({
                component: ToastificationSuccess,
                position: 'top-right',
                props: { text: response.message },
              });
            } else {
              AlertService.error(this, response.message);
            }
          })
        }
      });
    },

    generate: UserManageService.generate,
  },

  created() {
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
